










































import {Component, VModel, Vue, Watch} from 'vue-property-decorator';

@Component({})
export default class FilterRadioComponent extends Vue {

  @VModel({required: false, type: String})
  private selectedFilter!: 'all' | 'selected' | 'none';

  @Watch('selectedFilter')
  private watchFilter() {
    this.$emit('change');
  }
}
